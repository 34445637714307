import React from 'react';
import home from '../images/home.svg';
import {NavLink} from 'react-router-dom';
const Banner =() => {
return(
    <>
    <section id="banner">
    <div className="container-fluid">
    <div className="row homebanner_bg">
    <div className="col-10 mx-auto">
    <div className="row">
    <div className="col-lg-6 order-1 order-lg-2 banner-img">
    <img src={home} className="img-fluid animated" alt="Best IT Service Provider in Delhi NCR" />
    </div>
    <div className="col-md-6 pt-5 pt-lg-5 order-2 order-lg-1 d-flex justfy-content-center flex-column">
    <h2 className="h1 pt-3">Complete <strong> IT Solutions </strong>
    </h2>
    <h3 className="my-3 ">Benefits of working with us is you get all the required products and services under a single umbrella.</h3>
    <div className="mt-3 ">
    <NavLink className="btn-contact" to="contact">Contact Us</NavLink>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
    </>
);
};

export default Banner;