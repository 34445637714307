import React from 'react';
import InfiniteCarousel from 'react-leaf-carousel';
import hp from '../images/logo/hp.jpg';
import cisco from '../images/logo/cisco.jpg';
import dell from '../images/logo/dell.jpg';
import microsoft from '../images/logo/microsoft.jpg';
import sophos from '../images/logo/Sophos.jpg';
import seqrite from '../images/logo/seqrite.jpg';
import netgear from '../images/logo/netgear.jpg';
import dlink from '../images/logo/Dlink.jpg';
import lenovo from '../images/logo/lenovo.jpg';
const Partners=()=>{
return(
<>
<section>
<div className="container">
<header className="section-header">
<p className="text-center">Our Proud Partners</p>
</header>
</div>
</section>
<div className="container my-5">
<InfiniteCarousel
    breakpoints={[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
        {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ]}
    dots={false}
    arrows={false}
    showSides={true}
    sidesOpacity={.5}
    sideSize={.1}
    slidesToScroll={1}
    slidesSpacing={25}
    slidesToShow={6}
    autoCycle={true}
    cycleInterval={1500}
    pauseOnHover={true}
    // animationDuration={800}
  >
<img src={hp} alt="hp partner"/>
<img src={dlink} alt="dlink partner"/>
<img src={microsoft} alt="microsoft partner"/>
<img src={cisco} alt="cisco partner"/>
<img src={seqrite} alt="seqrite partner"/>
<img src={dell} alt="dell partner"/>
<img src={netgear} alt="netgear partner"/>
<img src={sophos} alt="sophos partner"/>
<img src={lenovo} alt="lenovo partner"/>
</InfiniteCarousel>
</div>
</>
);
}

export default Partners;

