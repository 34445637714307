import React from 'react';
import { MdMail, MdMessage } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { MdCall } from "react-icons/md";
import {MdSubject} from "react-icons/md";
import {FaEnvelope, FaPhoneAlt, FaUser} from "react-icons/fa";
import Contacttitle from './Contacttitle';
import Mapadd from './Mapadd';
import axios from '../../node_modules/axios/dist/axios.min.js';
const API_PATH = 'https://servicecom.in/api/php/index.php';
class Contact extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      phone: '',
      message: '',
      mailSent: false,
      error: null
    }
  }
  handleFormSubmit = e => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent
        })
      })
      .catch(error => this.setState({ error: error.message }));
  };
render(){
return(
    <>
    <Contacttitle/>
    <section id="contact" className="contact section-bg">
    <div className="container">
        <div className="row">
          <div className="col-lg-6">
          <div className="col-lg-12">
            <div className="info-box mb-4">
            <MdLocationOn size={38}/>
              <h4>Head Office</h4>
              <p>Regus, Level 9, Spaze I-Tech Park, A1 Tower, Sector – 49, Gurgaon -122018</p>
              <h4>Branch Office</h4>
              <p>518, 5th Floor, Ansal Tower 38, Nehru Place, New Delhi - 110019</p>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="info-box mb-4">
            <MdMail size={38}/>
              <h4>Email Us</h4>
              <p>sales@servicecom.in</p>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="info-box  mb-4">
              <MdCall size={38}/>
              <h4>Call Us</h4>
              <p>0124-6768655</p>
            </div>
          </div>
          </div>
          <div className="col-lg-6 my-4">
          <div className="wrapper py-3">
    <header>Send us a Message</header>
    <form action="#">
    <div className="dbl-field">
        <div className="field">
          <input type="text" name="name" placeholder="Enter your Name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })}/>
          <i><FaUser/></i>
        </div>
        <div className="field">
          <input type="text" name="email" placeholder="Enter your Email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}/>
          <i><FaEnvelope/></i>
        </div>
      </div>
      <div className="dbl-field">
      <div className="field">
          <input type="text" name="phone" placeholder="Enter your Phone"  value={this.state.phone} onChange={e => this.setState({ phone: e.target.value })}/>
          <i><FaPhoneAlt/></i>
        </div>
        <div className="field">
          <input type="text" name="subject" placeholder="Enter your Subject" value={this.state.subject} onChange={e => this.setState({ subject: e.target.value })}/>
          <i><MdSubject/></i>
        </div>  
        </div>     
      <div className="message">
        <textarea placeholder="Write your message" name="message" value={this.state.message} onChange={e => this.setState({ message: e.target.value })}></textarea>
       <i> <MdMessage/></i>
      </div>
      <div className="button-area">
        <button type="submit" onClick={e => this.handleFormSubmit(e)}>Send Message</button>
        <span></span>
      </div>
    <div>
  {this.state.mailSent &&
    <div className="success" >Thank you for contcting us. We'll get in touch with you soon.</div>
  }
  {this.state.error &&
    <div>Something went wrong. Please check your details again.</div>
  }
</div>
   </form>
</div>
      </div>
      </div>
</div>
      <div className="container-fluid">
        <div className="row">
<div className="col-lg-12 m-0 p-0">
<Mapadd/>
</div>
        </div>
            </div>
    </section>
    </>
);
};
}
export default Contact;