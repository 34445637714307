import React from 'react';
import contact from '../images/contactus.svg';
import {NavLink} from 'react-router-dom';
const Contacttitle =() => {
return(
    <>
    <section id="banner">
    <div className="container-fluid">
    <div className="row contactbanner_bg">
    <div className="col-10 mx-auto">
    <div className="row">
    <div className="col-lg-6 order-1 order-lg-2 banner-img">
    <img src={contact} className="img-fluid animated" alt="Best IT Service Provider in Delhi" />
    </div>
    <div className="col-md-6 pt-5 pt-lg-5 order-2 order-lg-1 d-flex justfy-content-center flex-column">
    <h2 className="h1 pt-3">Contact Us
    </h2>
    <h3 className="my-3 ">If you have questions, are looking for assistance, our team is ready to answer all your questions. or want to share your feedback with us, we’re listening.</h3>
    <div className="mt-3 ">
    <NavLink className="btn-contact" to="about">Know More About Our Company</NavLink>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
    </>
);
};

export default Contacttitle;