import React from 'react';
import { MdSecurity } from "react-icons/md";
import { TiSpanner, TiVendorMicrosoft } from "react-icons/ti";
import { GiAutoRepair } from "react-icons/gi";
import { BiChip } from "react-icons/bi";
import { FaHandsHelping, FaNetworkWired, FaProjectDiagram, FaServer } from "react-icons/fa";
const Servicecomp=()=>{
return(
<>
<section id="services" className="services section-bg">
      <div className="container">
        <div className="section-title">
          <h2>Services We Provide</h2>
        </div>
        <div className="row gy-2">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
               <MdSecurity size={92}  color={"#B3E0FF"}/> 
                </div>
                <div className="sdata">
              <h4>Security</h4>
              <p>Servicecom Technology provide various type of security like as Gateway level security, End Point, Email security, DLP and mobility</p>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <TiVendorMicrosoft size={92}/>
                </div>
                <div className="sdata">
              <h4>Microsoft Solutions</h4>
              <p>We are Microsoft Partner India offering Volume Licensing, Office 365, Cloud Solutions, Office 2019 and all other Microsoft products.</p>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
              <GiAutoRepair size={92}/>
                </div>
                <div className="sdata">
              <h4>System Integration</h4>
              <p>System integration is the process of integrating all the physical and virtual components of an organization system.</p>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <FaHandsHelping size={92}/>
                </div>
                <div className="sdata">
              <h4>Infrastructure Consult</h4>
              <p>Our highly skilled specialists will help determine the current state of IT in your organization, assist in the development of IT strategy that will open new horizons for business.</p>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <FaProjectDiagram size={92}/>
                </div>
                <div className="sdata">
              <h4>Turnkey Projects</h4>
              <p>We provide one stop for all types of office requirements at your doorstep, at the best possible prices, and the best quality with the best services with products.</p>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <FaNetworkWired size={92}/>
                </div>
                <div className="sdata">
              <h4>Active/Passive Networking</h4>
              <p>A computer network is a group of two or more interconnected computer systems. You can establish a network connection using either cable or wireless media.</p>
            </div>
          </div>
        </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <FaServer size={92}/>
                </div>
                <div className="sdata">
              <h4>Server and Storage Solutions</h4>
              <p>We provide end-to-end designing, development, and implementation for server, storage and infrastructure solutions to create reliable IT environments for various organizations.</p>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <BiChip size={92}/>
                </div>
                <div className="sdata">
              <h4>Power Conditioning Solutions</h4>
              <p>Servicecom has spent time in research and development to come up with a line of power conditioning products that can help consumers protect their devices and save energy charges.</p>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <TiSpanner size={92}/>
                </div>
                <div className="sdata">
              <h4>AMC/FMS And Resident Engineers</h4>
              <p>We specialize in providing onsite Laptop AMC services,computer amc for small and medium-sized businesses. Constant total support with required specific skills.</p>
            </div>
            </div>
          </div>

          </div>
          </div>
    </section>
</>
);
}
export default Servicecomp;