import React from 'react';
import Servicetitle from './Servicetitle';
import security from '../images/services/security.jpg';
import integration from '../images/services/integration.jpg';
import turnkey from '../images/services/turnkey.jpg';
import apc from '../images/services/apc.jpg';
import consult from '../images/services/consult.jpg';
import repair from '../images/services/repair.jpg';
import networking from '../images/services/networking.jpg';
import power from '../images/services/power.jpg';
import server from '../images/services/server.jpg';
import microsolution from '../images/services/microsolution.jpg';
import php from '../images/services/php.jpg';
const Services=()=>{
    return(
        <>
        <Servicetitle/>
        <div className="container">
        <div className="row my-3">
            <div className="col-md-4">
                <img src={security} alt="" style={{width : '100%'}}/>
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                    Security
                </h2>
                </div>
                <p>
                Servicecom Technology provide various type of security like as Gateway level security, End Point, Email security, DLP and mobility.</p>
                <dl>
                    <dt>Firewall & Gateway Level Security Solutions:- </dt>
                    <dd>In a simplified way, the main role of a firewall in a corporate network is to defend two or more networks (Internet and internal networks, or internal networks, Internet and DMZ, among others), interests and / or control requirements. Of companies.</dd>
                    <dd>Sophos, SonicWALL, Fortinet, Juniper</dd>
                    <dt>End Point Security:- </dt>
                    <dd>Endpoint security is the practice of securing endpoints or entry points of end-user devices such as desktops, laptops, and mobile devices from being exploited by malicious actors and campaigns. Endpoint security systems protect these endpoints on a network or in the cloud from cybersecurity threats.</dd>
                    <dd>Sophos, Quick Heal, MacAfee, TrendMicro</dd>
                    <dt>DLP Solution: -</dt>
                    <dd>Data loss prevention software detects potential data breaches / data pre-filtration transmissions and prevents them by monitoring, detecting and blocking sensitive data in use, in motion and at rest. The terms "data loss" and "data leak" are related and often used for interchange.</dd>
                    <dd>Websense, Symantec</dd>
                    <dt>Mobile Device Management</dt>
                    <dd>MDM or mobile device management is a software application that is used to manage endpoints in an enterprise such as smartphones, tablets, etc. With more and more employees using one or all of these devices, organizations of all shapes and sizes are now turning to mobile device management for enhanced data security and improved productivity.</dd>
                </dl>
                </div>
                </div>
            </div>
            
            <div className="row my-3">
            <div className="col-md-4">
            <img src={microsolution} alt="" style={{ width: '100%' }}/>
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                Microsoft Solutions
                </h2>
                </div>
                <p>
                Servicecom Technology is Microsoft Partner India offering Volume Licensing, Office 365, Cloud Solutions, Office 2019, Dynamics 365, Skype for Business, Microsoft Server, Azure Cloud, One Drive for Business and all other Microsoft products. Being the leading Microsoft Authorized Reseller in India, we provide software licensing services to customers across India including installation, deployment and IT support services for all Microsoft products and services. Servicecom Technology licenses Microsoft Volume Licensing consulting services to government, nonprofit, corporate and educational institutions.</p>
                <ul>
                    <li>Microsoft Office 365</li>
                    <li>Microsoft Azure</li>
                    <li>Cloud Migration</li>
                    <li>Microsoft Office</li>
                    <li>Windows</li>
                    <li>Windows Server</li>
                </ul>
                </div>
                </div>
            </div>
            <div className="row my-3">
            <div className="col-md-4">
            <img src={php} alt="" style={{ width: '100%' }}/>
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                PHP Web Development
                </h2>
                </div>
                <p>
                We understand the exclusive demands of your business and then provide solutions that are based on the concept of best PHP web development available in the industry to make your websites and apps look special.
                </p>
                <ul>
                    <li>PHP Web Development Services</li>
                    <li>PHP based CMS Development</li>
                    <li>Custom PHP Web Application Development</li>
                    <li>Portal Development Solutions</li>
                    <li>E-Commerce Development</li>
                    <li>Support & Maintainance</li>
                </ul>
                </div>
                </div>
            </div>
            <div className="row my-3">
            <div className="col-md-4">
            <img src={integration} alt="" style={{ width: '100%' }}/>
                </div>
                <div className="col-md-8">
                <div className="servicehead">
                <h2>
                System Integration
                </h2>
                </div>
                <div className="servicedata">
                <p>
                System integration is the process of integrating all the physical and virtual components of an organization system. Physical components include various machine systems, computer hardware, inventory, and more. Virtual components contain data stored in databases, software, and applications. The process integrates all of these components, so that working like a system is the main focus of system integration.
                </p>
                </div>
                </div>
            </div>
            <div className="row my-3">
            <div className="col-md-4">
            <img src={networking} alt="" style={{ width: '100%' }}/>
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                Networking Active & Passive
                </h2>
                </div>
                <p>
                A computer network is a group of two or more interconnected computer systems. You can establish a network connection using either cable or wireless media.</p>
                <dl>
                <dt>Networking Solution</dt>
                <dd>Lan, WAN, Cabling and Routing and Switching Solution</dd>
                <dt>Access Point Controlling Solution</dt>
                <dd>Indoor, Outdoor and Home Access Point Solution</dd>
                <dt>Surveillance and Access Control Solution</dt>
                <dd>CCTV Camera and DVR NVR , Access Control and Time and Attendance Solution</dd>
                </dl> 
                </div>
                </div>
            </div>
            <div className="row my-3">
            <div className="col-md-4">
            <img src={power} alt="" style={{ width: '100%' }}/>
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                Power Conditioning Solutions
                </h2>
                </div>
                <p>
                Responsible for repeated power outages, inadequate transmission and distribution systems, regular breakdown and load shedding, power theft, variation in supply frequency and voltage of the grid, harmonic level 22% THD loss, malicious operation or damage to critical electrical equipment Huh. Servicecom has spent years in research and development to come up with a line of power conditioning products that can help consumers protect their devices and save energy charges.</p>
                <dl>
                    <dt>APC, EMERSON, EATON</dt>
                </dl>
                </div>
                </div>
            </div>
            <div className="row my-3">
            <div className="col-md-4">
            <img src={consult} alt="" style={{ width: '100%' }}/>
                
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                Infrastructure Consult
                </h2>
                </div>
                <p>
                Our highly skilled specialists will help determine the current state of IT in your organization, assist in the development of IT strategy that will open new horizons for business, and successful strategy realization.
                </p>
                </div>
                </div>
            </div>
            <div className="row my-3">
            <div className="col-md-4">
            <img src={turnkey} alt="" style={{ width: '100%' }}/> 
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                IT Turnkey Projects
                </h2>
                </div>
                <p>
                We provide one stop for all types of office requirements at your doorstep, at the best possible prices, and the best quality with the best services with products. We provide total solutions to our customers and not just physical products.</p>
                <p> We not only focus on Pressley but also sales, post sales include:</p>
                <ul>
                    <li>Understand Client Requirements</li>
                    <li>Pre Project Plan</li>
                    <li>Design</li>
                    <li>Simulation</li>
                    <li>Production</li>
                    <li>Workshop Adjustment</li>
                    <li>Production Site Installation</li>
                    <li>Activation</li>
                    <li>Training</li>
                </ul>
                </div>
                </div>
            </div>
            

            
            <div className="row my-3">
            <div className="col-md-4">
            <img src={repair} alt="" style={{ width: '100%' }}/>
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                AMC/FMS And Resident Engineers
                </h2>
                </div>
                <p>
                We specialize in providing onsite Laptop AMC services,computer amc for small and medium-sized businesses. Our commitment to world-class customer service has made us the most respected on-site computer & laptop.
                It assists with the total documentation of the inventory, assets and whole processes. It saves a lot of money in recurring training costs or HR resources. Constant total support with required specific skills and availability of supporting the staff of IT all the time.
                </p>
                </div>
                </div>
            </div>

            <div className="row my-3">
            <div className="col-md-4">
            <img src={server} alt="" style={{ width: '100%' }}/>
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                Server and Storage Solutions
                </h2>
                </div>
                <p>
                We provide end-to-end designing, development, and implementation for server, storage and infrastructure solutions to create reliable IT environments for various organizations. We plan infrastructure solutions to fit the needs of customers perfectly and execute their critical business applications effectively. We provide reliable products for storage and data backup to meet all types of storage requirements.</p>
                <dl>
                    <dt>DELL, EMC, HP, LENOVO</dt>
                </dl>
                </div>
                </div>
            </div>
            
            <div className="row my-3">
            <div className="col-md-4">
            <img src={apc} alt="" style={{ width: '100%' }}/>
                </div>
                <div className="col-md-8">
                <div className="servicedata">
                <div className="servicehead">
                <h2>
                IT Equipment on Rental
                </h2>
                </div>
                <p>
                We provide laptop desktop and IT equipment rent, so that the customer can reduce the investment on IT equipment and focus on its productivity.
                </p>
                </div>
                </div>
            </div>

        </div>
        </>
    );
};

export default Services;
