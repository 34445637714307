import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/complogo.png';
import ScrollAnimation from 'react-animate-on-scroll';
const Navbar = () => {
return (
<>
<ScrollAnimation animateIn="fadeInUp"  initiallyVisible={true}>
<nav className="navbar sticky-top navbar-expand-lg navbar-light nav-bg">
  <div className="container-fluid p-0">
  <NavLink className="navbar-brand" to="/">
    <img src={logo} alt="Servicecom Logo"/>
    </NavLink>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-lg-0">
        <li className="nav-item">
          <NavLink exact activeClassName="menu_active" className="nav-link" to="/"> Home </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact activeClassName="menu_active" className="nav-link" to="/service">Services</NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact activeClassName="menu_active" className="nav-link" to="/about">About Us</NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact activeClassName="menu_active" className="nav-link" to="/contact">Contact Us</NavLink>
        </li>
      </ul>
    </div>
  </div>
</nav>
</ScrollAnimation>
</>
);
};
export default Navbar;