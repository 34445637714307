import React from 'react';
import Banner from './Banner';
import Whyus from './Whyus';
import Counter from './Counter';
import Cta from './Cta';
import Servicecomp from './Servicecomp';
import Partners from './Partners';
import Clients from './Clients';
import Benefits from './Benefits';
const Home =() => {
return(
    <>
    <Banner/>
    <Whyus/>
    <Counter/>
    <Servicecomp/>
    <Cta/>
    <Benefits/>
    <Partners/>
    <Clients/>
    </>
);
};

export default Home;