import React from 'react';
import install from '../images/installation.svg';
import sale from '../images/sale.svg';
import sol from '../images/solution.svg';
const Whyus =() =>{
    return(
<>
<section id="values" className="values">

      <div className="container">

      <header className="section-header">
          <p className="text-center">Why Choose Servicecom Technology?</p>
        </header>

        <div className="row mt-3">
          <div className="col-lg-4 ">
            <div className="box">
              <img src={sale} className="img-fluid" alt="post sale support"/>
              <h3>Post Sale Support</h3>
              <p>We maintain post sale support structure for our customers with our engineers available 24×7 which keeps your turnaround time to minimum in case of any support required</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <img src={sol} className="img-fluid" alt="solution"/>
              <h3>Optimum Support</h3>
              <p>We at Servicecom strive to provide you dedicated solutions to increase overall business process efficiency focus on providing the best and most cost-effective solutions to small and medium businesses.
              </p>
            </div>
          </div>


          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <img src={install} className="img-fluid" alt="installation"/>
              <h3>Installations</h3>
              <p>Team of certified engineers helps our customers to carry out installations in a professional manner, to help our customers meet timelines for their implementations.</p>
            </div>
          </div>

        </div>

      </div>

    </section>
</>
    );
}
export default Whyus;