import React from 'react';
import Abouttitle from './Abouttitle';
import Clients from './Clients';
import Cta from './Cta';
import Partners from './Partners';

const About =() => {
return(
    <>
    <Abouttitle/>
    <div className="container">
    <div className="row">
    <div className="col mx-auto">
    <div className="container">
    <div className="row mt-3">
    <div className="col-md-12">
    <p>
    To introduce Servicecom Technology is the IT Infrastructure System Integration Company. We provide services to Design, Plan, Implement, Manage and Review IT Networks.</p>

    <p>Servicecom Technology has been providing professional IT Support and managed IT Consultancy for businesses. We strive to give our clients Enterprise-level services and solutions at prices that work for businesses. Time and experience have helped us develop best practices and workflow procedures around a proactive philosophy designed to keep your focus on your business, not your technology.</p>

    <p>Our team of experts can become your outsourced IT department; responding to issues quickly, often before you even know about them. Covering everything from your servers and network infrastructure, to your computers, workstations, we provide end-to-end solutions for all of your technology needs.</p>

    <p>The state-of-the-art infrastructure selected is both scalable and flexible providing for small to corporate sized enterprises. It has a distinct location advantage; being midway between the national capital, Delhi.</p>

    <p>We bring to the world a whole new class of services and a unique approach to customer satisfaction. We serve to the world and an extensive portfolio of services in the most professional manner and also adhering to the industry standards. We share our knowledge of offshore development, our deep project management experiences, processes and methodology and wide network of personnel sources to ensure an outsourcing strategy that works for our clients. With strong plans of investing in people and technology, our strategy revolves around an inexorable commitment to provide superior services using world-class technology. The aim is to build a culture, based on our values of integrity and trust; supported by action with results.</p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <Partners/>
    <Cta/>
    <Clients/>
    
    
    </>
);
};

export default About;

