import React from 'react';
import cashify from '../images/clients/cashify.jpg';
import sgt from '../images/clients/sgt.jpg';
import extra from '../images/clients/extra.jpg';
import milano from '../images/clients/milano.jpg';
import talentedge from '../images/clients/talentedge.jpg';
import triveni from '../images/clients/triveni.jpg';
import spaze from '../images/clients/spaze.jpg';
import krbl from '../images/clients/krbl.jpg';
const Clients=()=>{
return (
<>
<section id="clients" className="clients-bg">
<div className="container" >

  <div className="section-header">
    <p className="text-center my-3">Our Precious Clients</p>
  </div>

  <div className="row g-0 clients-wrap clearfix">

    <div className="col-lg-3 col-md-4 col-xs-6">
      <div className="client-logo">
        <img src={cashify} className="img-fluid" alt="IT consultancy"/>
      </div>
    </div>

    <div className="col-lg-3 col-md-4 col-xs-6">
      <div className="client-logo">
        <img src={sgt} className="img-fluid" alt="Buy Commercial Laptops"/>
      </div>
    </div>

    <div className="col-lg-3 col-md-4 col-xs-6">
      <div className="client-logo">
        <img src={extra} className="img-fluid" alt="Best IT Service"/>
      </div>
    </div>

    <div className="col-lg-3 col-md-4 col-xs-6">
      <div className="client-logo">
        <img src={milano} className="img-fluid" alt="Microsoft Support"/>
      </div>
    </div>

    <div className="col-lg-3 col-md-4 col-xs-6">
      <div className="client-logo">
        <img src={talentedge} className="img-fluid" alt=""/>
      </div>
    </div>

    <div className="col-lg-3 col-md-4 col-xs-6">
      <div className="client-logo">
        <img src={triveni} className="img-fluid" alt=""/>
      </div>
    </div>

    <div className="col-lg-3 col-md-4 col-xs-6">
      <div className="client-logo">
        <img src={spaze} className="img-fluid" alt=""/>
      </div>
    </div>

    <div className="col-lg-3 col-md-4 col-xs-6">
      <div className="client-logo">
        <img src={krbl} className="img-fluid" alt=""/>
      </div>
    </div>

  </div>

</div>

</section>
</>
);
}
export default Clients;