import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import { Switch, Route, Redirect } from 'react-router-dom';
import Navbar from './components/Navbar';
import Copyright from './components/Copyright';
import Footer from "./components/Footer";
import ScrollToTop from './components/ScrollToTop';
const App =() => {
return(
    <>
    <ScrollToTop/>
    <Navbar/>
    <Switch>
    <Route exact path="/" component={Home}/>
    <Route exact path="/service" component={Services}/>
    <Route exact path="/about" component={About}/>
    <Route exact path="/contact" component={Contact}/>
    <Redirect to="/"/>
    </Switch>
    <Footer/>
    <Copyright/>
    </>
);
};

export default App;