import React from 'react';
import { NavLink } from 'react-router-dom';

const Cta=()=>{
    return(
<>
<section className="cta">
<div className="container">
<div className="jumbotron">
<div className="row">
<div className="col-lg-8 col-md-8 col-sm-12 text-center">
<p>Interested in Working with Us?</p>
</div>
<div className="col-lg-4 col-md-4 col-sm-12 text-center">
<NavLink className="btn-contact" to="contact"> Contact Us </NavLink>
</div>
</div>
</div>
</div>
</section>
</>
    );
}
export default  Cta;