import React from 'react';
import { BsFillHeartFill } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";
import { BsAwardFill } from "react-icons/bs";
import { BsTrophy } from "react-icons/bs";
import CountUp from "react-countup";
const Counter = () => {
  return (
    <>
      <section id="counts" className="counts">
        <div className="container-fluid">
          <div className="row gy-4">
            <div className="col-lg-3 col-md-6">
              <div className="count-box">
                <div className="row">
                  <div className="col-4">
                    <BsFillHeartFill size={64} color="red" />
                  </div>
                  <div className="col-8">
                    <CountUp duration={4.30} end={118} suffix={"+"} />
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-3 col-md-6">
              <div className="count-box">
                <div className="row">
                  <div className="col-4">
                    <BsFillPeopleFill size={64} color="#EE6C20" />
                  </div>
                  <div className="col-8">
                    <CountUp duration={4.30} end={13} suffix={"+"}></CountUp>
                    <p>Hard Workers</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="count-box">
                <div className="row">
                  <div className="col-4">
                    <BsAwardFill size={64} color="gold" />
                  </div>
                  <div className="col-8">
                    <CountUp duration={4.30} end={98} suffix={"%"} />
                    <p>Positive Reviews</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-3 col-md-6">
              <div className="count-box">
                <div className="row">
                  <div className="col-4">
                    <BsTrophy size={64} color="#15BE56" />
                  </div>
                  <div className="col-8">
                    <CountUp duration={4.30} end={48} suffix={"+"} />
                    <p>Total Projects</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>

    </>
  );
};

export default Counter;