import React from 'react';
import { NavLink } from 'react-router-dom';

const Copyright=() =>{
    return(
        <>
        <footer className="w-100 bg-dark text-center text-white"> 
        <p className="m-0">© Copyright <span className="font-wieight-bold">Servicecom Technology</span> | All Rights Reserved  </p>
        <p className="m-0">Designed by <NavLink exact to="/" className="text-decoration-none text-white">Servicecom Technology</NavLink></p>
        </footer>
        </>
    )
}
export default Copyright;