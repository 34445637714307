import React from 'react';
import benefit from '../images/benefits.svg';
import {RiCustomerService2Fill} from 'react-icons/ri';
import {AiFillDollarCircle} from 'react-icons/ai';
import {FaTruckLoading, FaCertificate} from 'react-icons/fa';

const Benefits=()=>{
    return(
        <>
        <section className="features">
        <div className="container" >
        <div className="row feature-icons" >
          <h3>Benefits of Working With Servicecom Technology</h3>
          <div className="row">

            <div className="col-xl-4 text-center">
              <img src={benefit} className="img-fluid p-4" alt=""/>
            </div>

            <div className="col-xl-8 d-flex content">
              <div className="row align-self-center gy-4">

                <div className="col-md-6 icon-box">
                  <i><RiCustomerService2Fill /></i>
                  <div>
                    <h4>Service</h4>
                    <p>Good Service & Solution in Servicecom Technology</p>
                  </div>
                </div>

                <div className="col-md-6 icon-box">
                  <i><FaCertificate/></i>
                  <div>
                    <h4>Quality</h4>
                    <p>Good Product Quality & Multiple Product Range In Servicecom Technology.</p>
                  </div>
                </div>

                <div className="col-md-6 icon-box">
                  <i><AiFillDollarCircle/></i>
                  <div>
                    <h4>Price</h4>
                    <p>Best Prices as Compared to Market</p>
                  </div>
                </div>

                <div className="col-md-6 icon-box">
                  <i><FaTruckLoading/></i>
                  <div>
                    <h4>Availability</h4>
                    <p>All Time Availability of Products in Our Stock</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        </div>
        </section>
        </>
    );
}
export default Benefits;