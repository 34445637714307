import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer=()=>{
return(
<>
<div className="container-fluid">
    <div className="row footer_bg">
        <div className="col-md-6">
        <h3 className="m-0 p-0">About Servicecom</h3>
        <hr className="bg-success mt-2 mb-1 p-0 d-inline-block mx-auto" width="60%" height="20px"/>
        <p>Servicecom Technology is the IT Infrastructure System Integration Company. We provide services to Design, Plan, Implement, Manage and Review IT Networks.We strive to give our clients Enterprise-level services and solutions at prices that work for businesses.</p>
        </div>
        <div className="col-md-6">
        <div className="row">
            <div className="col-md-4">
            <h3 className="m-0 p-0">Useful Links</h3>
        <hr className="bg-success mt-2 mb-1 p-0 d-inline-block mx-auto" width="60%" height="20px"/>
        <ul className="list-unstyled footmenu">
            <li><NavLink exact to="/">Home</NavLink></li>
            <li><NavLink exact to="about">About</NavLink></li>
            <li><NavLink exact to="service">Services</NavLink></li>
            <li><NavLink exact to="contact">Contact Us</NavLink></li>
        </ul>
            </div>
            <div className="col-md-8">
            <h3 className="m-0 p-0">Contact Servicecom</h3>
        <hr className="bg-success mt-2 mb-1 p-0 d-inline-block mx-auto" width="60%" height="20px"/>
            <p>Regus, Level 9, Spaze I-Tech Park, A1 Tower, Sector – 49, Sohna Road, Gurgaon -122018</p>
            <p>sales@servicecom.in</p>

            <p>0124-6768655</p>
</div>
</div>
</div>
</div>
</div>
</>
);
};

export default Footer;
