import React from 'react';
import about from '../images/aboutus.svg';
import {NavLink} from 'react-router-dom';
const Abouttitle =() => {
return(
    <>
    <section id="banner">
    <div className="container-fluid">
    <div className="row aboutbanner_bg">
    <div className="col-10 mx-auto">
    <div className="row">
    <div className="col-lg-6 order-1 order-lg-2 banner-img">
    <img src={about} className="img-fluid animated" alt="Best IT Service Provider in Delhi" />
    </div>
    <div className="col-md-6 pt-5 pt-lg-5 order-2 order-lg-1 d-flex justfy-content-center flex-column">
    <h2 className="h1 pt-3">About Us
    </h2>
    <h3 className="my-3">Servicecom Technology is an IT infrastructure system integration company providing services to design, plan, implement, manage and review IT networks.</h3>
    <div className="mt-3 ">
    <NavLink className="btn-contact" to="services">Check Out Our Services</NavLink>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
    </>
);
};

export default Abouttitle;